import React, { memo } from 'react'
import Products from '../Products/Products'
import CartOverview from './overview'
import { useProducts } from '../../../store'

const Cart = () => {
  const products = useProducts()
  return (
    <div className='container mt-4'>
      <div className='row'>
        <div className='col-sm-12'>
          <CartOverview />
        </div>
        <div className='col-sm-12'>
          {products && products.length > 0 ? (
            <Products items={products} />
          ) : (
            <h4>Your cart is empty</h4>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(Cart)
