import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  DECREMENT_QUANTITY,
  INCREMENT_QUANTITY,
  REMOVE_PRODUCT,
  useProductsDispatch,
} from '../../../store'
import { formatCurrencyString } from '../../../utils/stripe'

const CartItem = ({ name, currency, image, id, total, quantity, index }) => {
  const dispatch = useProductsDispatch()

  return (
    <tr>
      <th scope='row' className='p-4 ps-0'>
        {index}
      </th>
      <td className='p-4 ps-0'>{name}</td>
      <td className='p-4 ps-0'>
        {formatCurrencyString({
          value: parseInt(total, 10),
          currency,
        })}
      </td>
      <td className='p-4 ps-0'>
        <div className='input-group w-50'>
          <button
            className='btn btn-light-grey'
            type='button'
            id='button-addon1'
            onClick={() =>
              dispatch({
                type: DECREMENT_QUANTITY,
                id,
              })
            }
          >
            -
          </button>
          <input
            type='text'
            className='form-control text-center'
            aria-label='Example text with button addon'
            aria-describedby='button-addon1'
            value={quantity}
          />
          <button
            className='btn btn-light-grey'
            type='button'
            id='button-addon1'
            onClick={() =>
              dispatch({
                type: INCREMENT_QUANTITY,
                id,
              })
            }
          >
            +
          </button>
        </div>
      </td>
      <td className='p-4 ps-0'>
        <img src={image} alt={name} width='100' />
      </td>
      <td className='p-4 ps-0 pe-0'>
        <button
          aria-label='Remove product'
          className='shadow-none navbar-toggler border-0 text-primary nav-button'
          type='button'
          onClick={() =>
            dispatch({
              type: REMOVE_PRODUCT,
              id,
            })
          }
        >
          <i className='icon-cross fs-2' />
        </button>
      </td>
    </tr>
  )
}

CartItem.propTypes = {
  name: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

export default memo(CartItem)
