import React, { memo, useState } from 'react'
import { CLEAR_ALL, useProducts, useProductsDispatch } from '../../../store'
import getStripe, { formatCurrencyString } from '../../../utils/stripe'

const CartOverview = () => {
  const [loading, setLoading] = useState(false)

  const dispatch = useProductsDispatch()
  const products = useProducts()

  const currency = 'gbp'

  const handleSubmit = async () => {
    setLoading(true)

    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: products.map((product) => ({
        price: product.id,
        quantity: product.quantity,
      })),
      successUrl: `${window.location.origin}/page-2/`,
      cancelUrl: `${window.location.origin}/cart`,
      shippingAddressCollection: {
        allowedCountries: ['GB'],
      },
    })

    if (error) {
      setLoading(false)
    }
  }

  return (
    <div className='border-bottom border-3 pb-5'>
      <p>
        Number of Items:{' '}
        {products && products.length > 0
          ? products.reduce((sum, { quantity }) => sum + quantity, 0)
          : 0}
      </p>

      <p>
        Total:{' '}
        {formatCurrencyString({
          value: parseInt(
            products && products.length > 0
              ? products.reduce((sum, { total }) => sum + total, 0)
              : 0,
            10
          ),
          currency,
        })}
      </p>
      <div
        className='btn-toolbar'
        role='toolbar'
        aria-label='Toolbar with button groups'
      >
        <div className='btn-group me-2'>
          <button
            type='button'
            className='btn btn-success'
            disabled={loading}
            onClick={() => {
              setLoading(true)
              handleSubmit()
            }}
          >
            {loading ? 'Loading...' : 'Checkout'}
          </button>
        </div>
        <div className='btn-group me-2'>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() =>
              dispatch({
                type: CLEAR_ALL,
              })
            }
          >
            Clear cart
          </button>
        </div>
      </div>
    </div>
  )
}

export default memo(CartOverview)
