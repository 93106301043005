import * as React from 'react'

import Layout from '../components/layout'
// import Seo from '../components/seo'
import Cart from '../components/Shop/Cart/cart'

const CartPage = () => (
  <Layout>
    {/* <Seo title='Contact Us' /> */}
    <div className='container'>
      <Cart />
    </div>
  </Layout>
)

export default CartPage
