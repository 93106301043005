import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link, StaticQuery } from 'gatsby'
import CartItem from '../Cart/cartItem'
import { formatCurrencyString } from '../../../utils/stripe'

const Products = ({ page, items }) => {
  return (
    <StaticQuery
      query={graphql`
        query ProductPrices {
          prices: allStripePrice(
            filter: { active: { eq: true } }
            sort: { fields: [unit_amount] }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                active
                currency
                unit_amount
                product {
                  id
                  name
                  images
                }
              }
            }
          }
        }
      `}
      render={({ prices }) =>
        page === 'products' ? (
          <>
            <h1 className='text-black pt-5 font-secondary'>OUR PRODUCTS</h1>
            <div className='container pb-5 pt-2'>
              <div className='row'>
                {prices.edges.map(({ node: price }) => {
                  return (
                    <div
                      className='col-lg-3 col-md-4 col-sm-6 text-center mb-4'
                      key={price.id}
                    >
                      <Link
                        to={price.fields.slug}
                        className='text-decoration-none'
                      >
                        <img
                          className='img-fluid rounded mx-auto product-list--image'
                          src={price.product.images}
                          alt='Vegan, Gluten Free'
                        />
                        <h4 className='pt-3'>{price.product.name}</h4>
                        <div>Vegan, Gluten Free</div>
                        <strong className='text-green'>
                          {formatCurrencyString({
                            value: parseInt(price.unit_amount, 10),
                            currency: price.currency,
                          })}
                        </strong>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </>
        ) : (
          <table className='table w-100 me-4'>
            <thead>
              <tr>
                <th scope='col' className='ps-0'>
                  #
                </th>
                <th scope='col' className='ps-0'>
                  Name
                </th>
                <th scope='col' className='ps-0'>
                  Total
                </th>
                <th scope='col' className='ps-0'>
                  Quantity
                </th>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th scope='col' className='ps-0'>
                  &nbsp;
                </th>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th scope='col' className='ps-0'>
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, i) => {
                const { node } = prices.edges.find((x) => x.node.id === item.id)

                return (
                  <CartItem
                    key={item.id}
                    name={item.name}
                    price={item.price}
                    total={item.total}
                    quantity={item.quantity}
                    currency={node.currency}
                    image={node.product.images}
                    id={item.id}
                    index={i + 1}
                  />
                )
              })}
            </tbody>
          </table>
        )
      }
    />
  )
}

Products.propTypes = {
  page: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
}

Products.defaultProps = {
  page: '',
  items: [],
}

export default memo(Products)
